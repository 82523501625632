import { postRequest } from "../../../connection/network";
import {
  AUTHENTICATED,
  AUTH_ERROR,
  AUTH_LOADING,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from "../types";

const authApis = {
  login: "auth/login",
};

export const logoutAction = () => (dispatch) => {
  dispatch({ type: LOGOUT_SUCCESS });
};

export const IsAuthenticatedAction = () => (dispatch) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  if (!isLoggedIn) {
    return dispatch({ type: AUTH_ERROR });
  } else {
    dispatch({ type: AUTHENTICATED });
  }
};

export const login =
  ({ username, password }) =>
  (dispatch) => {
    dispatch({ type: AUTH_LOADING });

    return postRequest({
      body: {
        username,
        password,
      },
      endPoint: authApis.login,
    })
      .catch((error) => {
        dispatch({
          type: AUTH_ERROR,
          payload: "Invalid mobile, email or password",
        });
      })
      .then((res) => {
        localStorage.setItem("token", res.data.data.token);
        const {
          data: { user, token },
        } = res.data;

        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            token,
            user,
          },
        });
      });
  };
