import { Navigate, Route, Routes } from "react-router-dom";
import { lazy } from "react";
import { useSelector } from "react-redux";

import SuspenseComponent from "../Components/SuspenseComponent";
import { AppLoader } from "../splash";

const Main = lazy(() => import("../Layout/Main"));
const LoginPage = lazy(() => import("../Pages/LoginPage"));

export const Router = ({ mode, changeMode }) => {
  const { isLoading: isAuthLoading } = useSelector((s) => s?.auth);

  if (isAuthLoading) return <AppLoader />;

  return (
    <Routes>
      <Route
        path="login"
        element={<SuspenseComponent>{<LoginPage />}</SuspenseComponent>}
      />
      <Route path="/*" element={<Main />} />

      <Route
        path="404"
        element={<SuspenseComponent>{/* <NotFound /> */}</SuspenseComponent>}
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default Router;
