import { CssBaseline } from "@mui/material";
import { Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import getDesignTokens from "./theme";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import Splash, { AppLoader } from "./splash";

import moment from "moment";
import { CacheProvider } from "@emotion/react";
import { Provider } from "react-redux";
import store from "./store/index";

function App() {
  const { i18n } = useTranslation();

  const isRTL = i18n.language === "ar" ? "rtl" : "ltr";
  moment.locale(i18n.language);
  document.body.setAttribute("dir", isRTL);

  const theme = useMemo(() => {
    return createTheme({ ...getDesignTokens(), direction: isRTL });
  }, [isRTL]);

  let plugins = [prefixer];
  if (isRTL === "rtl") {
    plugins.push(rtlPlugin);
  }

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: plugins,
  });

  return (
    <Suspense fallback={<AppLoader />}>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div id="app">
            <Suspense fallback={<AppLoader />}>
              {/* <StyledSnackbarProvider
                maxSnack={4}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                classes={{
                  variantInfo: snackStyle.info,
                }}
                iconVariant={snackIcons}
                dense
                action={(snackbarKey) => (
                  <SnackbarCloseButton snackbarKey={snackbarKey} />
                )}
              > */}
              {/* <SnackbarUtilsConfigurator /> */}
              <Provider store={store}>
                <Splash isRTL={isRTL} />
              </Provider>
              {/* </StyledSnackbarProvider> */}
            </Suspense>
          </div>
        </ThemeProvider>
      </CacheProvider>
    </Suspense>
  );
}

export default App;
